import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
const queryString = require('query-string');

class Auth {
    async get_service(data) {
        try {
            // setApiAuthorizationHeader(token)
            const response = await authApi.get(`${keys.SERVER_URL}utils/new-category/?${data}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_masters(data) {
        let query = queryString.stringify(data.params);
        try {
            setApiAuthorizationHeader(data.token);
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/master/?${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_selected_masters(data) {
        let { token, id, ...rest } = data;
        let query = queryString.stringify(rest);
        // userdetails/master/?only_favorites=1
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/master/?only_favorites=1&${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_master_data_service(data) {
        try {
            setApiAuthorizationHeader(data.token);
            const master_services = await authApi.get(`${keys.SERVER_URL}userdetails/master-service/?user_id=${data.id}`);
            return master_services.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_service_by_address(data) {
        try {
            setApiAuthorizationHeader(data.token);
            const master_services = await authApi.post(`${keys.SERVER_URL}/schedule/get-services-with-address/`, data);
            return master_services.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_master_data(data) {
        let { token, id } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/user/${id}/?is_master=true`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async post_files(data) {
        let { token, ...rest } = data;
        let query = queryString.stringify(rest);
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}timeline/post/?${query}`);
            // const response = await authApi.get(`${keys.SERVER_URL}timeline/get-user-posts-only-file/${id}/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async review(data) {
        let { token, ...params } = data;
        let query = queryString.stringify(params);
        // http://api-beauty.annaniks.com/timeline/review/?from_user=&to_user=1
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}timeline/review/?from_user=&${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async availableDays(data) {
        let { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/schedule/get-available/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async masterFilters(data) {
        let { token, ...params } = data;
        if (!params.city) {
            delete params.city;
        }
        let query = queryString.stringify(params);
        // http://api-beauty.annaniks.com/timeline/review/?from_user=&to_user=1
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}schedule/masters-for-map/`, { ...params });
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async masterSchedule(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            // const res = await authApi.get(`${keys.SERVER_URL}schedule/check-service-availability/${rest.service}/?date=${rest.date}`);
            // if (res.data.available) {
            // const response = await authApi.post(`${keys.SERVER_URL}schedule/get-daily-schedule-by-service/`, rest);
            const response = await authApi.post(`${keys.SERVER_URL}schedule/get-daily-schedule/`, rest);
            return response;
            // } else {
            //     return [];
            // }

        } catch (error) {
            return error.response;
        }
    }
    async createOrder(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}schedule/create-new-order/?rurl=https://beautycils.app/master/${data.master_id}/register-master/registation-end`, rest);
            if (response.data.redirect_url) {
                const res = await authApi.get(`${keys.SERVER_URL}schedule/check-payment/${response.data.redirect_url}/`);
                return res;
            } else {
                return response.data;
            }

        } catch (error) {
            return error.response;
        }
    }
    async clientCreateOrder(data) {
        const { ...rest } = data;
        try {
            const response = await authApi.post(`${keys.SERVER_URL}schedule/client-create-new-order/`, rest);
            if (response.data.redirect_url) {
                const res = await authApi.get(`${keys.SERVER_URL}schedule/check-payment/${response.data.redirect_url}/`);
                return res;
            } else {
                return response.data;
            }
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async sendNewOrderCode(phone_number) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}schedule/send-new-order-code/`, { phone_number });
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async add_favorite_masters(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/userdetails/favorite-masters/add/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async remove_favorite_masters(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/userdetails/favorite-masters/remove/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_city(data) {
        const { search } = data;
        try {
            const response = await authApi.get(`${keys.SERVER_URL}/utils/city/?search=${search}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_city_lat_lng(data) {
        const { search } = data;
        try {
            const response = await authApi.get(`${keys.SERVER_URL}/utils/city/?slug=${search}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_closest_cities(data) {
        const { token, ...rest } = data
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/utils/get-closest-cities/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async masters_posts(data) {
        const { token, ...rest } = data
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/timeline/masters-posts/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
}
const category = new Auth();
export default category;
